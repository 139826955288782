<template>
  <div class="mini-cart__empty">
    <div class="mini-cart__empty-icon">
      <i class="fa-solid fa-cart-plus"></i>
    </div>
    <div class="mini-cart__empty-message">Your cart is empty.</div>
    <div class="mini-cart__empty-continue">
      <a href="" class="mini-cart__link" @click="closeMiniCart">
        <span>Continue shopping</span>
      </a>
    </div>
    <div class="mini-cart__empty-add">
      <a href="/main-catalogue-bulkadd" class="mini-cart__link">
        <span>Add by product code</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyCartLoggedIn',
  props: {
    closeMiniCart: {
      type: Function,
      required: true,
    },
  },
};
</script>
