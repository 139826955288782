import Vue from 'vue';
import Notifications from 'vue-notification';
import MiniCart from '../components/MiniCart.vue';

Vue.use(Notifications);

Vue.config.devtools = true;

// Import data from the Taco into the Vue object
const div_id = 'mcart';
const data_args = JSON.parse(document.getElementById(div_id).getAttribute('data-args'));

const mini_cart = new Vue({
  render: (h) => h(MiniCart, { props: { dboard: data_args } }),
}).$mount(`#${div_id}`);
