<template>
  <div class="mini-cart__active-edd leftright">
    <div class="mini-cart__header-text"
      :class="{'mini-cart__updating': noEddsYet}"
      v-if="noEddsYet">
      No EDDs yet
    </div>
    <div class="mini-cart__header-text" v-else>{{ displayDate }}</div>
    <div class="mini-cart__header-text">{{ num }} {{ itemWord }}</div>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/formatDate';

export default {
  name: 'EddHeader',
  props: {
    edd: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    noEddsYet: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    num() {
      return this.items.length;
    },
    itemWord() {
      return this.num === 1 ? 'item' : 'items';
    },
    displayDate() {
      // EDD not available
      if (this.edd === '.No EDD') {
        return 'Awaiting update from supplier';
      }

      // Handle preorder items
      if (this.edd === '.Preorder') {
        return `Ships from ${this.items[0].ltoos_display_date}`;
      }

      // Calculate today and tomorrow dates
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      // Determine if date is today or tomorrow
      const eddDate = new Date(this.edd);
      if (eddDate.toDateString() === today.toDateString()) {
        return 'By Today';
      }
      if (eddDate.toDateString() === tomorrow.toDateString()) {
        return 'By Tomorrow';
      }
      formatDate(eddDate, false);
      return `By ${formatDate(eddDate, false)}`;
    },
  },
};

</script>
