<template>
  <div class="mini-cart__active" >
    <div class="mini-cart__active-items">
      <div class="mini-cart__active-waiting" v-if="!hasItems">
        <div class="mini-cart__spinner-large">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
      <div class="mini-cart__active-itemsbyedd" v-else>
        <div v-for="edd in edds" :key="edd">
          <EddHeader :edd="edd" :items="itemsByEdd[edd]" :noEddsYet="noEddsYet" />
          <MiniItem v-for="item in itemsByEdd[edd]"
            :key="item.itemid"
            :item="item"
            :updateItem="updateItem"
            :removeItem="removeItem"
            :itemsUpdating="itemsUpdating"
            :itemsDeleting="itemsDeleting"
            :deleteFailed="deleteFailed"
            :updateFailed="updateFailed"
            :supTagMessage="supTagMessage"
          />
        </div>
      </div>
    </div>
    <div class="mini-cart__active-footer">
      <div class="mini-cart__active-loggedout" v-if="loggedOut">
        <h2 class="header2-24 ">Log in or Register to continue</h2>
        <div class="mini-cart__active-text">
          Get access to pricing and special offers by being logged in or registering!
        </div>
        <button @click="loginLightbox" type="button"
          class="wc-button wc-button--primary wc-button--fullwidth">
          <span class="label">Log in</span>
        </button>
        <button @click="gotoRegistration" type="button"
          class="wc-button wc-button--secondary wc-button--fullwidth">
          <span class="label">Register</span>
        </button>
      </div>
      <div v-else>
        <div class="spaceddiv-x2" v-if="cartMinimumOrder">
          <message
            message-type="warn"
            extras="compact nomargin">
            <div class="stacked-left-spaced">
              Your order must be at least {{ cartMinimumOrder.limit }} to checkout
            </div>
          </message>
        </div>
        <div class="leftright">
          <div class="mini-cart__active-summary">
            <div class="mini-cart__active-gst" v-if="showTotal">
              Cart total ({{ dboard.showgst }})
            </div>
            <div class="mini-cart__active-total" :class="{'mini-cart__updating': updating}"
              v-if="showTotal">
              {{ dashboardTotal }}
            </div>
          </div>
          <button @click="gotoCart"
            type="button"
            class="wc-button wc-button--big wc-button--primary"
            :class="{'wc-button--disabled': !!updating}"
            :disabled="!!updating">
            <span class="label" v-if="updating">
              Loading
              <i class="fa fa-spinner fa-spin"></i>
            </span>
            <span class="label" v-else>
              Review cart
              <i class="fa-solid fa-chevron-right"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniItem from './MiniItem.vue';
import EddHeader from './EddHeader.vue';
import Message from '../common/Message/Message.vue';

export default {
  name: 'ActiveCart',
  components: {
    MiniItem,
    EddHeader,
    Message,
  },
  props: {
    dboard: {
      type: Object,
      required: true,
    },
    loggedOut: {
      type: Function,
      required: true,
    },
    loginLightbox: {
      type: Function,
      required: true,
    },
    gotoRegistration: {
      type: Function,
      required: true,
    },
    dashboardTotal: {
      type: String,
      required: true,
    },
    gotoCart: {
      type: Function,
      required: true,
    },
    hasItems: {
      type: Function,
      required: true,
    },
    itemsByEdd: {
      type: Object,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
    updateItem: {
      type: Function,
      required: true,
    },
    cartMinimumOrder: {
      type: Object,
      required: true,
    },
    itemsUpdating: {
      type: Array,
      required: true,
    },
    itemsDeleting: {
      type: Array,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
    updateFailed: {
      type: Boolean,
      required: true,
    },
    deleteFailed: {
      type: Boolean,
      required: true,
    },
    noEddsYet: {
      type: Boolean,
      required: true,
    },
    supTagMessage: {
      type: String,
      required: true,
    },

  },
  computed: {
    edds() {
      return Object.keys(this.itemsByEdd).sort();
    },
    showTotal() {
      return window.nxDatalayer.global.displayprices;
    },
  },
};
</script>
