<template>
  <div class="mini-cart__dashboard">
    <div id="dashboard_container" @click="openMiniCart">
      <div class="mini-cart__spinner" v-if="updatingDashboard">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <div v-else id="dashboard">
        <div class="cart_button">
          <div class="cart-price-box" v-if="displayPrices">
            <div id="total_price">{{ dashboardTotal }}</div>
            <div class="gst_note">{{ dboard.showgst }}</div>
          </div>
          <div id="cart">
            <a href="" class="cart_image" aria-label="Cart" title="Cart"></a>
            <div class="mini-cart__dashboard_removed" v-if="showRemoved">
              <i class="fa-solid fa-triangle-exclamation"></i>
            </div>
            <div id="item_count" v-else-if="dashboardItems">{{ dashboardItems }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  props: {
    dboard: {
      type: Object,
      required: true,
    },
    openMiniCart: {
      type: Function,
      required: true,
    },
    updatingDashboard: {
      type: Boolean,
      required: true,
    },
    displayPrices: {
      type: Function,
      required: true,
    },
    dashboardTotal: String,
    dashboardItems: Number,
    removedItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateMobileDashboard() {
      // update the count on mobile dashboard
      const target = document.getElementById('dashboard_container_mob');
      if (!target) return;
      const num = this.dashboardItems ?
        `<div id="item_count_mob">${this.dashboardItems}</div>`
        : '';
      target.innerHTML = `<div id="cart_mob">
        <a href="/main-orders-viewcurrent" class="cart_image" aria-label="Cart" title="Cart"></a>
        ${num}
      </div>`;
    },
  },
  computed: {
    showNum() {
      return this.dashboardItems > 0;
    },
    showRemoved() {
      return this.removedItems.length > 0;
    },
  },
  updated() {
    this.updateMobileDashboard();
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      this.updateMobileDashboard();
    });
  },
};
</script>
