<template>
  <div
    v-if="showCartProgress"
    class="mini-cart__header_progress">
    <div class="mini-cart__header_progress-row- leftright">
      <div
        v-if="getFreeOrderGap"
        class="mini-cart__header_progress-text"
        :class="{'mini-cart__updating': updating}">
        Spend {{ this.getFreeOrderGap }} more to get <b>free delivery!</b>
      </div>
      <div
        v-else
        class="mini-cart__header_progress-text"
        :class="{'mini-cart__updating': updating}">
        You've got <b>free delivery!</b>
      </div>
      <div
        v-if="getThresholdValue"
        class="mini-cart__header_progress-text">
        {{ this.getThresholdValue }}
      </div>
    </div>
    <div class="mini-cart__header_progress-bar"  :class="{'mini-cart__updating': updating}">
      <div :class="getCartProgressClass"
        :style="{ width: this.getCartTotalPercentage + '%' }"
        v-if="!updating">
      </div>
    </div>
    <div class="mini-cart__header_progress-row left">
      <div class="mini-cart__header_progress-text-left" :class="{'mini-cart__updating': updating}">
        *Freight on bulky items may still apply.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartProgress',
  props: {
    cartProgress: {
      type: Object,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showCartProgress() {
      return this.cartProgress?.cart_progress_show || false;
    },
    getFreeOrderGap() {
      return this.cartProgress?.free_order_gap || 0;
    },
    getThresholdValue() {
      return this.cartProgress?.threshold_value || 0;
    },
    getCartTotalPercentage() {
      return this.cartProgress?.cart_progress_percent;
    },
    getCartProgressClass() {
      return this.getCartTotalPercentage < 100 ? 'mini-cart__header_progress-bar-fill' : 'mini-cart__header_progress-bar-fill-green';
    },
  },
};
</script>
