<template>
  <div class="mini-cart__empty">
    <div class="mini-cart__empty-loggedout">
      <div class="mini-cart__empty-login">
        <h2 class="header2-24 ">You aren't logged in!</h2>
        <div class="mini-cart__empty-text">
          Get the most of your shopping experience by logging in
        </div>
        <button @click="loginLightbox" type="button"
          class="wc-button wc-button--primary wc-button--fullwidth">
          <span class="label">Log in</span>
        </button>
      </div>
      <div class="mini-cart__empty-register">
        <h2 class="header2-24">Not registered?</h2>
        <div class="mini-cart__empty-text">
          Get access to pricing and special offers by registering
        </div>
        <button @click="gotoRegistration" type="button"
          class="wc-button wc-button--primary wc-button--fullwidth">
          <span class="label">Register</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyCartLoggedOut',
  props: {
    loginLightbox: {
      type: Function,
      required: true,
    },
    gotoRegistration: {
      type: Function,
      required: true,
    },
  },
};
</script>
