<template>
  <div class="stacked-left-spaced">
    <div v-for="item in removedItems" :key="item.index">
      <Message message-type="warn" extras="compact nomargin" >
        <div class="mini-cart__removed">
          <div class="stacked-left-spaced">
            <span class="mini-cart__removed_description">
              <b>{{ item.description}}{{ item.prodcode }}</b>
              has been removed from your order because
            {{ messages[item.shortcode] }}</span>
          </div>
          <button class="wc-button wc-button--secondary wc-button--small"
            @click="updateRemoved(item.prodcode)">
            <span class="label">Dismiss</span>
          </button>
          <button class="wc-button wc-button--primary wc-button--small"
            v-if="canViewSimilar(item.shortcode)"
            @click="viewSimilar(item)">
            <span class="label">View similar</span>
          </button>
        </div>
      </Message>
    </div>
  </div>
</template>

<script>
import Message from '../common/Message/Message.vue';
// deleted_item_removed => '16',
// del  => "it is no longer in our catalogue",
// backorder_items_removed => '17',
// backorder => "it is being reviewed, and cannot be added to your order",
// inaccessible_item_removed => '18',
// deny => "you no longer have access to it",
// discontinued_item_removed => '19',
// disc => "we have run out of stock",
// special_item_removed => '20',
// special => "it is not in our catalogue, and you don't have permission to order special products",

export default {
  name: 'ViewRemoved',
  props: {
    removedItems: {
      type: Array,
      required: true,
    },
    updateRemoved: {
      type: Function,
      required: true,
    },
  },
  components: {
    Message,
  },
  computed: {
    num() {
      return this.items.length;
    },
    itemWord() {
      return this.num === 1 ? 'item' : 'items';
    },
    messages() {
      return {
        W16: 'it is no longer in our catalogue',
        W17: 'it is being reviewed, and cannot be added to your order',
        W18: 'you no longer have access to it',
        W19: 'we have run out of stock',
        W20: 'it is not in our catalogue, and you don\'t have permission to order special products',
      };
    },
  },
  methods: {
    viewSimilar(item) {
      const desc = encodeURI(item.description);
      document.location =
        `/main-catalogue-similar/${desc}/${item.prodcode}?fs_src=mini_cart`;
    },
    message() {
      return 'This item is no longer available';
    },
    canViewSimilar(shortcode) {
      return shortcode === 'W19';
    },
  },
};

</script>
