<template>
  <div :class="drawerClass" v-if="showDrawer">
    <div class="nx-drawer__modal-bg" :class="{ 'nx-drawer__modal-bg-visible': drawerBg }"
      @click="closeDrawer"></div>
    <div class="nx-drawer nx-drawer-right" :style="drawerStyle">
      <div class="nx-drawer__middle nx-drawer__middle-right" :style="middleStyle">
        <div class="nx-drawer__inner">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Drawer',
  props: {
    drawerClass: {
      type: String,
      required: true,
    },
    drawerBg: {
      type: Boolean,
      required: true,
    },
    drawerStyle: {
      type: Object,
      required: true,
    },
    middleStyle: {
      type: Object,
      required: true,
    },
    showDrawer: {
      type: Boolean,
      required: true,
    },
    closeDrawer: {
      type: Function,
      required: true,
    },
  },
  computed: {
  },
  methods: {
  },
};
</script>
